import { Stack, Box, Typography } from "@mui/material";
import PlayControls from "./components/PlayControls";

import Graphs from "./components/Graphs";
import { useEffect } from "react";
import store from "./mobx";
import { useNavigate } from "react-router";

function MixQuick() {
  const navigate = useNavigate();

  useEffect(() => {
    return () => store.audioProcessingStore.stop();
  }, []);

  return (
    <Stack
      alignItems="center"
      sx={{
        width: "100vw",
        height: "100vh",
        // background: "linear-gradient(to bottom right, white, grey, black)",
        bgcolor: "black",
        backgroundImage: `url("main_background5.jpg")`,
        // backgroundSize: "cover",
        // overflow: "scroll",
      }}
    >
      <Stack justifyContent="center" alignItems={"center"}>
        <Box>
          <Box sx={{ mb: 1 }}>
            <Stack>
              <Typography
                variant="h2"
                color="black"
                style={{
                  fontFamily: "Permanent Marker",
                  WebkitTextStroke: "2px white",
                  cursor: "pointer",
                }}
                onClick={() => navigate("/")}
              >
                Mix Quick
              </Typography>
              <Box alignSelf={"flex-end"}>
                <Typography
                  variant="h6"
                  color="black"
                  style={{
                    fontFamily: "Permanent Marker",
                    WebkitTextStroke: "1px white",
                  }}
                >
                  by Last.Year
                </Typography>
              </Box>
            </Stack>
          </Box>
          {/* spectrograph goes here */}
        </Box>
        <PlayControls />
        <Graphs />
      </Stack>
    </Stack>
  );
}

export default MixQuick;
