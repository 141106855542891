import { observer } from "mobx-react-lite";
import { Paper, Stack, Typography } from "@mui/material";
import { ThreeRenderer } from "./ThreeRenderer";
import { Html } from "@react-three/drei";

import { Clipper } from "../Modulators/Clipper";
import { Gain } from "../Modulators/Gain";

import { Fragment } from "react";
import { Oscilloscope } from "./Oscilloscope";

import store from "../../mobx";

const BAR_SCALING_FACTOR = 50;
const LEFT_ORIGIN = -60;
const BOTTOM_ORIGIN = -30;
const BAR_WIDTH = 20;

const INDICATOR_OFFSET = 46;

const BAR_SPACING = 15;

// the purpose of this component is to make a separate three.js instance
// and dedicate it to its own working
function _MeterdB() {
  const bars = [
    {
      name: "Pre Signal",
      Component: () => (
        <>
          <ClipperIndicator
            x={LEFT_ORIGIN + INDICATOR_OFFSET - BAR_WIDTH / 2}
          />
          <PreSignal />
          <TickBar />
        </>
      ),
    },
    {
      name: "Clipped Signal",
      Component: () => (
        <>
          <ClipperIndicator
            x={LEFT_ORIGIN + INDICATOR_OFFSET - BAR_WIDTH / 2}
          />
          <ClippedSignal color={"red"} />
          <TickBar />
        </>
      ),
    },
    {
      name: "Gain Reduction",
      Component: () => (
        <>
          <GainReductionSignal color="green" />
          <TickBar reverse={true} />
        </>
      ),
    },
    {
      name: "Master Signal",
      Component: () => (
        <>
          <MasterSignal color="yellow" />
          <TickBar />
        </>
      ),
    },
  ];

  return (
    <Paper
      sx={{
        //
        // bgcolor: "#CCC",
        bgcolor: "primary.main",
        p: 1,
      }}
    >
      <Oscilloscope />

      <Stack direction="row" justifyContent={"flex-start"}>
        <Clipper />
        <ThreeRenderer
          // titleComponent={<Typography>db Meter</Typography>}
          stackProps={{
            ml: 2,
            spacing: 0,
            width: 670,
          }}
        >
          {bars.map(({ name, Component }, i) => (
            <group
              key={i}
              position={[LEFT_ORIGIN + i * (BAR_WIDTH + BAR_SPACING), 0, 0]}
            >
              <Html position={[-10, 30, 0]}>
                <Typography variant="caption" noWrap>
                  {name}
                </Typography>
              </Html>
              <Component />
            </group>
          ))}
        </ThreeRenderer>
        <Gain />
      </Stack>
    </Paper>
  );
}

const ClipperIndicator = observer(function ClipperIndicator({ x }) {
  const { audioProcessingStore } = store;

  const complement =
    1 -
    audioProcessingStore.getNormalized_dB(audioProcessingStore.clipAmountdB);
  const clipperHeight = complement * BAR_SCALING_FACTOR;

  const ceilingIndicatorWidth = 22;
  const ceilingIndactorHeight = 1;

  return (
    <>
      {/* rightward facing indicator */}
      <mesh
        position={[
          x + ceilingIndicatorWidth / 2 + 14,
          clipperHeight - ceilingIndactorHeight / 2 + BOTTOM_ORIGIN,
          1,
        ]}
      >
        <planeGeometry args={[ceilingIndicatorWidth, ceilingIndactorHeight]} />
        <meshBasicMaterial color="yellow" />
      </mesh>
    </>
  );
});

function SignaldB({ dbLevel, x = 0, color = "white" }) {
  return (
    <>
      <mesh position={[x, dbLevel / 2 + BOTTOM_ORIGIN, 0]}>
        <planeGeometry args={[BAR_WIDTH, dbLevel]} />
        <meshBasicMaterial color={color} />
      </mesh>
    </>
  );
}

const PreSignal = observer(function Signal({ x = 0, color = "white" }) {
  const { audioProcessingStore } = store;

  const preclippedLeveldB =
    audioProcessingStore.dbLevelPre * BAR_SCALING_FACTOR;

  return <SignaldB dbLevel={preclippedLeveldB} x={x} color={color} />;
});

const ClippedSignal = observer(function Signal({ x = 0, color = "white" }) {
  const { audioProcessingStore } = store;
  const clippedLeveldB =
    audioProcessingStore.dbLevelPostClipping * BAR_SCALING_FACTOR;

  return <SignaldB dbLevel={clippedLeveldB} x={x} color={color} />;
});

const GainReductionSignal = observer(function Signal({
  x = 0,
  color = "white",
}) {
  const { audioProcessingStore } = store;

  const dbRemoved =
    (-audioProcessingStore.clipAmountdB / 70) * BAR_SCALING_FACTOR;

  return <SignaldB dbLevel={dbRemoved} x={x} color={color} />;
});

const MasterSignal = observer(function Signal({ x = 0, color = "white" }) {
  const { audioProcessingStore } = store;

  const level_dB = audioProcessingStore.dbLevelMaster * BAR_SCALING_FACTOR;

  return <SignaldB dbLevel={level_dB} x={x} color={color} />;
});

const NUMBER_OF_TICK_BARS = 16;
const MIN_DB = -70;

const DB_CONTRIBUTION = MIN_DB / NUMBER_OF_TICK_BARS;

const DB_LEVELS = Array(NUMBER_OF_TICK_BARS)
  .fill(null)
  .map((_, i) => {
    const dB = i * DB_CONTRIBUTION;

    return dB;
  })
  .reverse();

function TickBar({ reverse = false }) {
  const xPosition = BAR_WIDTH / 2 + 1;

  const tickDistance = BAR_SCALING_FACTOR / DB_LEVELS.length;

  const dbLevels = reverse ? [...DB_LEVELS].reverse() : DB_LEVELS;

  return (
    <>
      <group>
        {dbLevels.map((dB, i) => {
          const distance = tickDistance * (i + 1);

          const extraDistance = reverse ? -tickDistance : 0;

          return (
            <Fragment key={i}>
              <mesh
                position={[
                  xPosition,
                  distance + extraDistance + BOTTOM_ORIGIN,
                  0,
                ]}
              >
                <planeGeometry args={[1, 0.5]} />
                <meshBasicMaterial color="black" />
                {/* Add the text for the dB level */}
              </mesh>

              <Html
                position={[
                  xPosition + 2,
                  distance + extraDistance + BOTTOM_ORIGIN,
                  0,
                ]}
              >
                <Typography
                  fontSize={12}
                  style={{
                    transform: "translate(0%, -50%)", // Center the text
                  }}
                >
                  {reverse ? `+${-dB.toFixed(1)}` : `-${-dB.toFixed(1)}`}
                </Typography>
              </Html>
            </Fragment>
          );
        })}
      </group>
      <mesh position={[xPosition, BAR_SCALING_FACTOR / 2 + BOTTOM_ORIGIN, 0]}>
        <planeGeometry args={[0.5, BAR_SCALING_FACTOR]} />
        <meshBasicMaterial color={"black"} />
      </mesh>
    </>
  );
}

// function ClippedSignalDb({})

const MeterdB = observer(_MeterdB);

// const MeterdB = _MeterdB;

export { MeterdB };
