import { Stack } from "@mui/material";

import { observer } from "mobx-react-lite";
import store from "../../mobx";
import { ModulatorSlider } from "../Sliders/ModulatorSlider";
import { dbToGain } from "tone";

const ARRAY_ITEMS = 16;

const marks = Array(ARRAY_ITEMS)
  .fill(null)
  .map((v, i) => ({
    label: `${i}`,
    value: i,
  }));

function _Clipper() {
  const { audioProcessingStore, clipperStore } = store;

  return (
    <Stack>
      <ModulatorSlider
        label="Clip (dB)"
        defaultValue={0}
        min={0}
        max={marks.length}
        marks={marks}
        onChange={(event) => {
          const dBIncrement = event.target.value;

          const gain = dbToGain(dBIncrement);

          const normalizedGain = 1 / dbToGain(dBIncrement);

          audioProcessingStore.set.clipAmountGain(normalizedGain);

          if (clipperStore.options.autoGain) {
            audioProcessingStore.set.postClipAmountGain(gain);
          }

          audioProcessingStore.render();
        }}
      />
    </Stack>
  );
}

const Clipper = observer(_Clipper);

export { Clipper };
