import { Box, Button, CardMedia, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";

import { Stack } from "@mui/system";
import { KeyboardArrowDown } from "@mui/icons-material";

import store from "../../mobx";

const BG_COLOR = "#080d17";

export const ValueProp = observer(function ValueProp() {
  const navigate = useNavigate();

  const { isPhone } = store.mediaBreakpointStore;

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      height="100%"
      sx={{
        position: "relative",
      }}
    >
      <Stack
        spacing={isPhone ? 3 : 4}
        justifyContent="center"
        alignItems="center"
        sx={{
          px: 2,

          height: "100%",
          width: isPhone ? undefined : "45vw",
          position: isPhone ? "absolute" : "relative",
          bgcolor: isPhone ? `${BG_COLOR}cc` : undefined,
        }}
      >
        <Stack alignItems="center">
          <Typography
            variant="h2"
            textAlign="center"
            sx={(theme) => ({
              fontFamily: "PermanentMarker",
              fontSize: isPhone ? 44 : 56,
              mb: isPhone ? undefined : -0.5,
              lineHeight: "36px",
              WebkitTextStroke: 2,
              WebkitTextStrokeColor: theme.palette.altPrimary.main,
              color: "black",
            })}
          >
            Mix.Quick
          </Typography>
        </Stack>
        <Stack justifyContent="center" alignItems="center" spacing={4}>
          <Box>
            <Typography
              variant="h2"
              textAlign="center"
              sx={(theme) => ({
                fontFamily: "PermanentMarker",
                fontSize: isPhone ? 36 : 48,
                WebkitTextStroke: 1,
                WebkitTextStrokeColor: theme.palette.secondary.main,
                color: "black",
              })}
            >
              A production game changer.
            </Typography>
            <Typography
              variant="h2"
              textAlign="center"
              sx={(theme) => ({
                fontFamily: "PermanentMarker",
                fontSize: isPhone ? 22 : 34,
                WebkitTextStroke: 1,
                WebkitTextStrokeColor: theme.palette.secondary.main,
                color: "black",
              })}
            >
              Beginner friendly, high quality, and versatile.
            </Typography>
          </Box>

          {/* <Box /> */}
          <Button
            variant="contained"
            sx={(theme) => ({
              fontFamily: "PermanentMarker",
              fontSize: isPhone ? 24 : 32,
              WebkitTextStroke: 1,
              WebkitTextStrokeColor: "black",
              letterSpacing: 4,
              borderRadius: 4,
              border: "1px solid",
              // borderColor: "altSecondary.main",
            })}
            onClick={() => navigate("/mixquick")}
          >
            Demo
          </Button>
        </Stack>
      </Stack>
      <Stack
        justifyContent="center"
        alignItems="flex-start"
        sx={{ width: "50vw" }}
      >
        <Box sx={{ borderRadius: 4,
          
          // bgcolor: "red"
           }}>
          <CardMedia
            component="img"
            src="/mix_quick_demo_7.jpg"
            sx={{
              ...(isPhone
                ? {
                    height: "80%",
                    width: "100%",
                    // width: 1920 / 3,
                    // pt: 0.5,
                    objectFit: "cover",
                    objectPosition: "top",
                  }
                : {
                    height: "90%",
                    objectFit: "contain",
                    objectPosition: "left",

                    // boxShadow: `0 0 16px 16px ${BG_COLOR} inset`,
                    // boxShadow: `0 0 16px 16px #fff1`,
                  }),
            }}
          />
        </Box>
      </Stack>

      {/* <KeyboardArrowDown
        sx={{
          position: "absolute",
          left: "calc(50% - 30px)",
          bottom: isPhone ? 56 : 8,
          width: 60,
          height: 60,
          // transform: "rotate(-90deg)",
          color: "secondary.light",
          borderRadius: 15,
          WebkitTapHighlightColor: "transparent",
          cursor: "pointer",
          "&:hover": {
            bgcolor: "#fff1",
          },
        }}
        onClick={() => {
          const section2 = document.getElementById("section-2");

          section2.scrollIntoView({ behavior: "smooth" });
        }}
      /> */}
    </Stack>
  );
});
