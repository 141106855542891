import { observer } from "mobx-react-lite";
import { Paper, Typography } from "@mui/material";
import { ThreeRenderer } from "./ThreeRenderer";
import { computeColorBasedOnIndex } from "../../mobx/stores/audioProcessing/utilties";
import store from "../../mobx";
import * as THREE from "three";

const BAR_SCALING_FACTOR = 20;
const LEFT_ORIGIN = -60;
const BOTTOM_ORIGIN = -30;
const LINE_Y_OFFSET = 5;
const BAR_WIDTH = 2.1;

const xScale = 1;

// the purpose of this component is to make a separate three.js instance
// and dedicate it to its own working
function _EQVisualizer() {
  const { audioProcessingStore } = store;

  const magnitudeObjects = audioProcessingStore.magnitudeObjects;

  const widthScalingFactor = 60 / magnitudeObjects.length;
  const barWidth = BAR_WIDTH * widthScalingFactor;

  const points = magnitudeObjects.map((magnitudeObject, i) => {
    const { avgValueDb } = magnitudeObject;
    const xOffset = i * barWidth + LEFT_ORIGIN; // X-coordinate
    const yOffset =
      avgValueDb * BAR_SCALING_FACTOR + BOTTOM_ORIGIN + LINE_Y_OFFSET; // Y-coordinate
    return new THREE.Vector3(xOffset, yOffset, 0); // Create a point in 3D space
  });

  const lineGeometry = new THREE.BufferGeometry().setFromPoints(points);

  return (
    <Paper sx={{ bgcolor: "black" }}>
      <ThreeRenderer
        titleComponent={<Typography color="white">EQ</Typography>}
        stackProps={{
          spacing: 0,
        }}
      >
        {/* <line>
          <bufferGeometry attach="geometry" {...lineGeometry} />
          <lineBasicMaterial color="cyan" />
        </line> */}
        {magnitudeObjects.map((magnitudeObject, i) => {
          const { avgValueDb } = magnitudeObject;
          const xOffset = i * barWidth * xScale; // Map to scale using barWidth

          const height = Math.max(avgValueDb * BAR_SCALING_FACTOR, 1);

          const color = computeColorBasedOnIndex(i, magnitudeObjects);

          return (
            <mesh
              key={i}
              //
              position={[xOffset + LEFT_ORIGIN, height / 2 + BOTTOM_ORIGIN, 0]}
            >
              <planeGeometry args={[barWidth, height]} />
              <meshBasicMaterial color={color} />
            </mesh>
          );
        })}
      </ThreeRenderer>
    </Paper>
  );
}

const EQVisualizer = observer(_EQVisualizer);

// const EQVisualizer = _EQVisualizer;

export { EQVisualizer };
