import { makeAutoObservable } from "mobx";
import FileSystemStore from "./stores/filesystem";
import AudioProcessingStore from "./stores/audioProcessing";
import ClipperStore from "./stores/clipper";
import MediaBreakpointStore from "./stores/mediaBreakpoint";

class GlobalStore {
  data = [];
  isLoading = false;

  constructor() {
    this.fileSystemStore = new FileSystemStore(this);
    this.audioProcessingStore = new AudioProcessingStore(this);
    this.clipperStore = new ClipperStore(this);
    this.mediaBreakpointStore = new MediaBreakpointStore(this);

    makeAutoObservable(this);
  }
}

export default GlobalStore;
