import { observer } from "mobx-react-lite";
import { Divider, Paper, Stack, Typography } from "@mui/material";
import { ThreeRenderer } from "./ThreeRenderer";
import store from "../../mobx";
import { Line } from "@react-three/drei";

const BAR_SCALING_FACTOR = 30;
const LEFT_ORIGIN = -140;
const BOTTOM_ORIGIN = -0;
const barWidth = 1.2;

// the purpose of this component is to make a separate three.js instance
// and dedicate it to its own working
function _Oscilloscope() {
  const { audioProcessingStore } = store;

  // const finalBins = bins
  const finalBins = audioProcessingStore.scopeBins;

  return (
    <Stack alignItems="center">
      <ThreeRenderer
        titleComponent={<Typography color="white">Oscilloscope</Typography>}
        stackProps={{
          spacing: 0,
          width: 700,
          sx: { border: "1px solid black", borderRadius: 2, bgcolor: "black" },
        }}
        style={{ backgroundColor: "black" }} // Add this line for black background
      >
        {finalBins.length > 0 ? (
          <Line
            points={finalBins.map((bin, i) => [
              i * barWidth + LEFT_ORIGIN, // X-coordinate
              bin * BAR_SCALING_FACTOR + BOTTOM_ORIGIN, // Y-coordinate (scaled height)
              0, // Z-coordinate
            ])}
            color="lime"
            lineWidth={2} // Adjust thickness
            dashed={false} // Set to true if you want a dashed line
          />
        ) : null}
      </ThreeRenderer>
      <Divider sx={{ my: 1 }} />
    </Stack>
  );
}

const Oscilloscope = observer(_Oscilloscope);

export { Oscilloscope };
