import { Checkbox, FormControlLabel, FormGroup, Stack } from "@mui/material";

import { observer } from "mobx-react-lite";
import store from "../../mobx";
import { ModulatorSlider } from "../Sliders/ModulatorSlider";
import { dbToGain } from "../../mobx/stores/audioProcessing/utilties/gainToDb";
import { gainToDb } from "tone";

const ARRAY_ITEMS = 16;

const marks = Array(ARRAY_ITEMS)
  .fill(null)
  .map((v, i) => ({
    label: `${i}`,
    value: i,
  }));

function _Gain() {
  const { audioProcessingStore, clipperStore } = store;

  const autoGainEnabled = clipperStore.options.autoGain;

  return (
    <Stack pr={2}>
      <ModulatorSlider
        label="Gain (dB)"
        defaultValue={0}
        min={0}
        max={marks.length}
        marks={marks}
        disabled={autoGainEnabled || !audioProcessingStore.playingSignal}
        value={Math.round(gainToDb(audioProcessingStore.postClipAmountGain))}
        onChange={(event) => {
          const dBIncrement = event.target.value;

          const gain = dbToGain(dBIncrement);

          const maxGain = Math.max(gain, 1);

          audioProcessingStore.set.postClipAmountGain(maxGain);
          audioProcessingStore.render();
        }}
      />
      <FormGroup

      // sx={{ position: "absolute" }}
      >
        <FormControlLabel
          control={
            <Checkbox
              color="altPrimary.main"
              checked={autoGainEnabled}
              onChange={() =>
                clipperStore.set.options.autoGain(!autoGainEnabled)
              }
              sx={{
                color: "altPrimary.main",
              }}
            />
          }
          label="Auto Gain"
        />
      </FormGroup>
    </Stack>
  );
}

const Gain = observer(_Gain);

export { Gain };
