import { useEffect } from "react";
import { Box } from "@mui/material";
import { observer } from "mobx-react-lite";

import { Stack } from "@mui/system";

import store from "../../mobx";
import { DotGridCluster } from "../../components/Pizazz/DotGridBorder";

import { NavRail } from "./NavRail";
import { ValueProp } from "./ValueProp";
import { FeaturesCarousel } from "./FeaturesCarousel";
import { Video } from "./Video";
import { Testimonials } from "./Testimonials";
import { Socials } from "./Socials";

const BG_COLOR = "#080d17";
const CLOSE_TO_BLACK = "#04060b";

export default observer(function LandingPage() {
  const { isPhone } = store.mediaBreakpointStore;

  useEffect(() => {
    store.appBarStore.set.hidden(true);
    return () => store.appBarStore.set.hidden(false);
  }, []);

  const sectionSpacing = isPhone ? 4 : 8;

  const dotSpacing = isPhone ? 2.5 : 3.75;

  const titleSpacing = isPhone ? 3 : 5;

  const section2Items = [
    {
      component: (
        <FeaturesCarousel
          title="Features"
          spacing={isPhone ? 5 : titleSpacing}
        />
      ),
      spacing: isPhone ? 2 : undefined,
      // height: "80vh",
    },
    // {
    //   component: <Video spacing={titleSpacing} />,
    //   // height: "80vh"
    // },
    {
      component: <Testimonials spacing={titleSpacing} />,
      // height: "80vh",
    },
    {
      component: (
        <Socials
          title="Speed me up!"
          titleProps={
            isPhone
              ? {
                  sx: {
                    fontSize: 34,
                  },
                }
              : undefined
          }
          spacing={isPhone ? 3.5 : 4.5}
        />
      ),
      spacing: isPhone ? 2 : 3.25,

      // sx: { pb: 1 },
    },
  ];

  return (
    <Stack
      sx={{
        bgcolor: "rgb(255, 255, 255, 0.2)",
        width: "100vw",
        overflowX: "hidden",
        overflowY: "scroll",
      }}
    >
      {/* <NavRail /> */}

      <Section
        sx={{
          height: "100vh",
          width: "100vw",
          bgcolor: BG_COLOR,
          // background: `linear-gradient(to left, ${BG_COLOR}, ${BG_COLOR}, ${BG_COLOR}, ${CLOSE_TO_BLACK})`,
          backgroundImage: `url('main_background5.jpg')`,
        }}
      >
        <ValueProp />
      </Section>

      {/* <Box
        id={"section-2"}
        sx={(theme) => ({
          pb: isPhone ? 1.25 : 2.5,
          // background: `linear-gradient(to left bottom,  ${theme.palette.primary.light}, ${theme.palette.primary.light}, ${theme.palette.primary.dark})`,
          // backgroundImage: `url('main_background5.jpg')`,
          background: `linear-gradient(to bottom,  rgb(245, 124, 0), rgb(245, 124, 0), rgb(245, 124, 0))`,
          bgcolor: "primary.dark",
        })}
      >
        <Stack spacing={sectionSpacing}>
          {section2Items.map(({ component, spacing, sx }, index) => {
            return (
              <Stack
                key={index}
                index={index}
                spacing={spacing || dotSpacing}
                sx={sx}
              >
                <DotLine
                  direction={index % 2 === 0 ? "right" : "left"}
                  speedMs={index === section2Items.length - 1 ? 750 : undefined}
                />
                {component}
              </Stack>
            );
          })}
        </Stack>
      </Box> */}
    </Stack>
  );
});

function Section({ children, ...props }) {
  return <Box {...props}>{children}</Box>;
}

function DotLine({ direction = "left", speedMs = 1000 }) {
  return (
    <Box sx={{ pt: 1 }}>
      <DotGridCluster
        reverseVertical
        alternate
        number={2}
        dotSize={8}
        dotColor={"#fff2"}
        direction={direction}
        speedMs={speedMs}
        speedOffset={20}
        animationOffset={0}
        sx={{
          height: 16,
        }}
      />
    </Box>
  );
}
